import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_table_action_button = _resolveComponent("tm-table-action-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, null, {
    menu: _withCtx(() => [
      _createVNode(_component_tm_dropdown_item, {
        icon: "visibility",
        label: "View",
        to: { name: 'base.contacts.contactDetails', params: { id: '1' } }
      }, null, 8, ["to"]),
      _createVNode(_component_tm_dropdown_item, {
        icon: "edit",
        label: "Edit",
        onClick: _ctx.openModalEditContact
      }, null, 8, ["onClick"]),
      _createVNode(_component_tm_dropdown_item, {
        icon: "block",
        label: "Block",
        onClick: _ctx.openBlockContactModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_tm_dropdown_item, {
        icon: "delete",
        label: "Delete",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDeleteContactModal(`${_ctx.contactLine.person.fullName} ${_ctx.contactLine.phone}`)))
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_table_action_button)
    ]),
    _: 1
  }))
}