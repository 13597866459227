import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "d-flex align-center"
}
const _hoisted_2 = {
  key: 0,
  class: "d-flex align-center font-color-inherit font-weight-normal w100pr"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_skeleton = _resolveComponent("tm-skeleton")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_highlight = _resolveComponent("highlight")!
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_contacts_segments_details_dropdown = _resolveComponent("contacts-segments-details-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "item-key": _ctx.itemKey,
    "items-name": _ctx.itemsName,
    "show-selection": _ctx.showSelection,
    class: "contacts-table"
  }, {
    "body-cell-full-name-slot": _withCtx((props) => [
      (!_ctx.isLoadingMode)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: { name: 'base.contacts.contactDetails', params: { id: '1', shared: _ctx.isShared } }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_person, {
                name: props.row.person.fullName,
                "avatar-url": props.row.person.avatar,
                "avatar-color": props.row.person.avatarColor,
                search: _ctx.search,
                "avatar-size": "small"
              }, null, 8, ["name", "avatar-url", "avatar-color", "search"])
            ]),
            _: 2
          }, 1032, ["to"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_tm_skeleton, {
              type: "circle",
              width: "28px",
              height: "28px",
              class: "mr-2"
            }),
            _createVNode(_component_tm_skeleton, {
              type: "QChip",
              "random-width": "",
              height: "12px"
            })
          ]))
    ]),
    "body-cell-type-slot": _withCtx((props) => [
      (!_ctx.isLoadingMode)
        ? (_openBlock(), _createBlock(_component_tm_status, _normalizeProps(_mergeProps({ key: 0 }, props.row.type)), null, 16))
        : (_openBlock(), _createBlock(_component_tm_skeleton, {
            key: 1,
            width: "60px",
            height: "16px",
            type: "QChip"
          }))
    ]),
    "body-cell-phone": _withCtx((props) => [
      (!_ctx.isLoadingMode)
        ? (_openBlock(), _createBlock(_component_tm_country, {
            key: 0,
            country: {
          id: props.row.country.id,
          name: props.row.phone,
        },
            size: "xSmall"
          }, null, 8, ["country"]))
        : (_openBlock(), _createBlock(_component_tm_skeleton, {
            key: 1,
            type: "QChip",
            width: "94px",
            height: "16px"
          }))
    ]),
    "body-cell-organization-slot": _withCtx((props) => [
      (!_ctx.isLoadingMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_tm_avatar, {
              name: props.row.organization.organizationName,
              url: require(`@/mock/images/organizations/Logo-Org-0${_ctx.randomPic()}.png`),
              color: "white",
              class: "mr-2"
            }, null, 8, ["name", "url"]),
            _createVNode(_component_tm_ellipsis, { class: "w100pr" }, {
              default: _withCtx(() => [
                _createVNode(_component_highlight, {
                  text: props.row.organization.organizationName,
                  search: _ctx.search
                }, null, 8, ["text", "search"])
              ]),
              _: 2
            }, 1024)
          ]))
        : (_openBlock(), _createBlock(_component_tm_skeleton, {
            key: 1,
            type: "QChip",
            width: "94px",
            height: "16px"
          }))
    ]),
    "body-cell-actions": _withCtx((props) => [
      (!_ctx.isLoadingMode)
        ? (_openBlock(), _createBlock(_component_contacts_segments_details_dropdown, {
            key: 0,
            "contact-line": props.row
          }, null, 8, ["contact-line"]))
        : (_openBlock(), _createBlock(_component_tm_skeleton, {
            key: 1,
            type: "circle",
            width: "16px",
            height: "16px"
          }))
    ]),
    _: 1
  }, 8, ["headers", "items", "item-key", "items-name", "show-selection"]))
}