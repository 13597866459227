
import { defineComponent, computed, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import ContactsSegmentsDetailsTable from '@/components/pages/contacts/segments/details/ContactsSegmentsDetailsTable.vue'
import ContactsSegmentsDetailsFilter from '@/components/pages/contacts/segments/details/ContactsSegmentsDetailsFilter.vue'
import ContactsSegmentsDetailsEmpty from '@/components/pages/contacts/segments/details/ContactsSegmentsDetailsEmpty.vue'
import ContactsSegmentsDetailsActions from '@/components/pages/contacts/segments/details/ContactsSegmentsDetailsActions.vue'
import ViewSaver from '@/components/shared/viewSaver/ViewSaver.vue'
import { getTableData } from '@/services/tableService'
import type { BreadcrumbsLink, TableHeaders } from '@/definitions/shared/types'
import type { ContactListsDetails } from '@/definitions/contacts/lists/types'
import { useModes } from '@/compositions/modes'
import useContactsListsOpenModal from '@/compositions/contacts/useContactsListsOpenModal'
import { useRoute } from 'vue-router'
import { segmentsDetailsFilters, segmentsDetailsFiltersDefault } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'

export default defineComponent({
  name: 'ContactsListsDetails',
  components: {
    PageContent,
    DetailsHero,
    ContactsSegmentsDetailsTable,
    ContactsSegmentsDetailsFilter,
    ContactsSegmentsDetailsEmpty,
    ContactsSegmentsDetailsActions,
    ViewSaver,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const title = ref(isEmptyMode.value ? 'new segment' : 'USA clients')
    const contactsNumber = ref(isEmptyMode.value ? 0 : 88)
    const route = useRoute()
    const { openEditListsModal } = useContactsListsOpenModal()

    const breadcrumbsLink: BreadcrumbsLink[] = [
      {
        label: 'Contacts',
        name: 'base.contacts',
      },
      {
        label: 'Segments',
        name: 'base.contacts.segments',
      },
      {
        label: title.value,
      },
    ]

    const search = ref('')
    const { filters } = useFilters(segmentsDetailsFilters)

    const selected = ref<ContactListsDetails[]>([])

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Full name', value: 'full-name-slot' },
      { text: 'Phone', value: 'phone' },
      { text: 'Email', value: 'email' },
      { text: 'Organization', value: 'organization-slot' },
      { text: 'Contact type', value: 'type-slot' },
    ])
    const tableItems = ref<ContactListsDetails[]>(getTableData('contactsListsDetails'))
    const filteredTableItems = computed<ContactListsDetails[]>(() => {
      return tableItems.value.filter((item) => item.person.fullName.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      route,
      search,
      title,
      contactsNumber,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
      openEditListsModal,
      isEmptyMode,
      breadcrumbsLink,
      filters,
      segmentsDetailsFiltersDefault,
    }
  },
})
