import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_tm_dropdown_download = _resolveComponent("tm-dropdown-download")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    ref: "topFilterRef",
    "show-filters": "",
    "hide-columns-additional-buttons": "",
    selected: _ctx.selected,
    filters: _ctx.filters,
    "changed-filters-count": _ctx.filters.length,
    "conditions-filter": "",
    "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:filters', $event))),
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:model-value', $event))),
    "onUpdate:radioValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:radio-value', $event))),
    onReset: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('reset')))
  }, {
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Send SMS",
        icon: "chat"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Refresh",
        icon: "refresh"
      })
    ]),
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Send SMS",
        icon: "chat"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Edit",
        icon: "edit",
        onClick: _ctx.clickEdit
      }, null, 8, ["onClick"]),
      _createVNode(_component_top_filter_button, {
        text: "Add note",
        icon: "article",
        onClick: _ctx.openNoteModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_top_filter_button, {
        text: "Add to list",
        icon: "tmi-person-add",
        onClick: _ctx.openEditListsModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_top_filter_button, {
        text: "Add tags",
        icon: "sell",
        onClick: _ctx.openEditTagsModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_tm_dropdown_download),
      _createVNode(_component_top_filter_button, {
        text: "Delete",
        icon: "delete",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDeleteContactsModal(_ctx.selected)))
      })
    ]),
    _: 1
  }, 8, ["selected", "filters", "changed-filters-count"]))
}