
import { defineComponent } from 'vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import UseContactsSegmentsOpenModal from '@/compositions/contacts/useContactsSegmentsOpenModal'

export default defineComponent({
  components: {
    TmAutoSizePanelItemButton,
    TmDropdownDownload,
  },
  props: {
    segmentName: {
      type: String,
    },
  },
  setup() {
    const { openEditSegmentsModal, openDeleteSegmentModal, openDuplicateSegmentsModal } = UseContactsSegmentsOpenModal()

    return {
      openEditSegmentsModal,
      openDeleteSegmentModal,
      openDuplicateSegmentsModal,
    }
  },
})
