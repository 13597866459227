
import { defineComponent } from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import FilterAdd from '@/components/shared/filters/FilterAdd.vue'
import { addContactFilters } from '@/definitions/shared/filters/filtersLib/data'

export default defineComponent({
  name: 'ContactsSegmentsDetailsEmpty',
  components: {
    TmButton,
    TmEmptyState,
    FilterAdd,
  },
  emits: ['addFilter'],
  setup() {
    return {
      addContactFilters,
    }
  },
})
