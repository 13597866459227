import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_tm_dropdown_download = _resolveComponent("tm-dropdown-download")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_tm_auto_size_panel_item_button, {
      icon: "tmi-pin",
      text: "Pin"
    }),
    _createVNode(_component_tm_auto_size_panel_item_button, {
      icon: "tmi-duplicate",
      text: "Duplicate",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDuplicateSegmentsModal(_ctx.segmentName)))
    }),
    _createVNode(_component_tm_auto_size_panel_item_button, {
      icon: "edit",
      text: "Rename",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openEditSegmentsModal(_ctx.segmentName)))
    }),
    _createVNode(_component_tm_dropdown_download),
    _createVNode(_component_tm_auto_size_panel_item_button, {
      icon: "delete",
      text: "Delete",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDeleteSegmentModal(_ctx.segmentName)))
    })
  ], 64))
}